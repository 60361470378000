import './App.css';
import { Typography } from '@mui/material';
import {Saga} from './components/Saga';

function App() {
  return (
    <>
      <header style={{ textAlign: 'center' }}>
        <Typography
          variant="h1"
          component="h2">
          saga
        </Typography>
      </header>
      <div style={{ padding: 30 }}>
        <Saga />
      </div>
    </>
  );
}

export default App;
