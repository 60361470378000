import { useEffect, useMemo, useState } from 'react';
import { gql, useLazyQuery } from "@apollo/client";
import { debounce } from '../utils/utilities';
import { SagaResults } from "./SagaResults";
import { Autocomplete, CircularProgress, Paper, TextField, Typography } from '@mui/material';

const QUERY = gql`
query AddressServiceability($input: AddressServiceabilityInput!) {
  addressServiceability(input: $input) {
    address {
      addressId
      components {
        preDirection
        primaryNumber
        street
        postDirection
        secondaryNumber
        secondaryDesignator
        city
        state
        zip
        zip9
      }
      census {
        censusBlock
        censusTract
      }
      meta {
        matched
      }
    }
    broadband {
      deployments {
        provider {
          name
          providerId
        }
        services {
          technology
          download
          upload
          aliases
        }
      }
        
      serviceArea {
        provider {
          providerId
          name
        }
        serviceAreaId
        coverage
        technologies
      }
    }
    offers {
      city
      state
      zip
      requestDetails
      providers {
        provider
        segmentId
        products
      }
    }
  }
}
`;

export function Saga() {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState();

  const autoCompleteLoading = open && options.length === 0;

  const [getData, {called, loading, error}] = useLazyQuery(QUERY, {
    onCompleted: (data) => {
      setOption(data);
    },
  });

  // Debounce autocomplete search
  const handleSearch = useMemo(() => debounce(setSearch, 250), []);

  // Handles selection of autocomplete option
  const handleChange = async (val) => {
    if (!val) return;

    const res = await fetch('https://us-street.api.smartystreets.com/street-address?' +
      new URLSearchParams({
        key: process.env.REACT_APP_SMARTY_KEY,
        city: val.city,
        state: val.state,
        street: val.street_line,
        secondary: val.secondary
      }));

    const data = await res.json();
    getData({
      variables: {
        input: {
          address: {
            primary: data[0].components.primary_number,
            secondary: `${data[0].components.secondary_designator} ${data[0].components.secondary_number}`,
            street: `${data[0].components.street_name} ${data[0].components.street_suffix}`,
            city: data[0].components.city_name,
            state: data[0].components.state_abbreviation,
            zip: data[0].components.zipcode,
            point: {
              latitude: data[0].metadata.latitude,
              longitude: data[0].metadata.longitude,
            } 
          },
          harbinger: {
            apiKey: process.env.REACT_APP_HARBINGER_API_KEY,
          }
        },
      },
    })
  };

  // Updates options on search change
  useEffect(() => {
    const getAddressResults = async () => {
      const res = await fetch('https://us-autocomplete-pro.api.smartystreets.com/lookup?' +
        new URLSearchParams({
          'key': process.env.REACT_APP_SMARTY_KEY,
          'search': search,
        }));
      const data = await res.json();
      setOptions(data.suggestions || []);
    };

    if (search) {
      getAddressResults();
    }
  }, [search]);

  // Reset options on close
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      <Paper
        elevation={3}
        style={{
          padding: 30,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>

        <Typography
          variant="h5"
          component="h5"
          style={{lineHeight: '4rem',}}>
          Broadband Services by Street Address
        </Typography>

        <form>
          <Autocomplete
            id="autocomplete"
            sx={{width: 500}}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(event, val) => {
              handleChange(val)
            }}
            getOptionLabel={(option) => buildAddress(option)}
            options={options}
            loading={autoCompleteLoading}
            isOptionEqualToValue={(option, value) => option.text === value.text}
            onInputChange={(event, val) => handleSearch(val)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter an address"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {autoCompleteLoading ? <CircularProgress color="inherit" size={20}/> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </form>
      </Paper>
      <Paper
        elevation={3}
        style={{
          marginTop: 30,
          padding: 30,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {option && <SagaResults sagaData={option}/>}
        {called && loading && <div>Loading...</div>}
        {error && <div>Error :(</div>}
      </Paper>
    </>
  );
}

function buildAddress(suggestion) {
  let whiteSpace = "";
  if (suggestion.secondary || suggestion.entries > 1) {
    if (suggestion.entries > 1) {
      suggestion.secondary += " (" + suggestion.entries + " more entries)";
    }
    whiteSpace = " ";
  }
  return suggestion.street_line + whiteSpace + suggestion.secondary + " " + suggestion.city + ", " + suggestion.state + " " + suggestion.zipcode;
}