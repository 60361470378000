import React from "react";
import {
    Divider,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

const StyledDivider = styled(Divider)(({theme}) => ({
    width: '100%',
    margin: '60px 0',
}))

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ulStyle = {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
}

function ProviderServices(props) {
    return (
        <StyledTableRow>
            <TableCell>
                <div>
                  <h4>{props.holdingCompanyName}</h4>
                </div>
            </TableCell>
            <TableCell>
                <ProviderTechnologies
                    technologies={props.technologies}
                    holdingCompanyId={props.holdingCompanyId}
                />
            </TableCell>
        </StyledTableRow>
    );
}

function ProviderTechnologies({ technologies, holdingCompanyId }) {
    const renderItem = (item) => {
        return (
            <span key={holdingCompanyId + item.technology.toString()} style={{marginRight: 10}}>
                <h4>{item.technology}</h4> D:{item.download} U:{item.upload}
            </span>
        );
    };

    const renderAreaItem = (item) => {
        return (
            <span key={holdingCompanyId + item.technology.toString()} style={{marginRight: 10}}>
                <h4>{item.technology}*</h4>
                D:{item.download} U:{item.upload}
                {item.coverage ? (<p>Coverage: {item.coverage}</p>) : null}
            </span>
        );
    };

    return (
        <>
        {technologies.map((item) => (
            item.isFromArea ? renderAreaItem(item) : renderItem(item)
        ))}
        </>
    );
}

function HarbingerOffers(props) {
    const products = props.products.map((p) => (
        <HarbingerProduct key={p.id}
                          name={p.name}
                          pricing={p.pricing}
                          promotions={p.promotions}
                          attributes={p.attributes}/>
    ))
    const hasProducts = products.length > 0;
    return (
        <>
            <TableRow>
                <TableCell colSpan={4} align={"center"}><Typography variant="h6" gutterBottom
                                                                    component="div">{props.name}</Typography></TableCell>
            </TableRow>
            {hasProducts ? products : <NoProducts/>}
        </>
    );
}

function NoProducts(props) {
    return (
        <TableRow>
            <TableCell colSpan={4}>No products</TableCell>
        </TableRow>
    )
}

function HarbingerProduct(props) {
    const hasSpeeds = props.attributes && props.attributes.downloadSpeed && props.attributes.uploadSpeed
    return (
        <StyledTableRow>
            <TableCell>{props.name}</TableCell>
            <TableCell>{hasSpeeds && props.attributes.downloadSpeed + '/' + props.attributes.uploadSpeed}</TableCell>
            <TableCell>{props.pricing && props.pricing.base.amount}</TableCell>
            <TableCell>
                {props.promotions &&
                <ul style={ulStyle}>
                    {props.promotions.map((p) => (
                        <li key={p.id}>{p.name}</li>
                    ))}
                </ul>
                }
            </TableCell>
        </StyledTableRow>
    );
}

// Searches for matching values between arr1 and arr2 returns boolean
function arraysContainMatchingItem (arr1, arr2) {
    return arr2.findIndex((item) => arr1.indexOf(item) >= 0) >= 0;
}

export function SagaResults(props) {
    if (props.sagaData.length === 0) {
        return;
    }

    const formattedServices = () => {
        const deployments = props.sagaData.addressServiceability.broadband.deployments;
        const areas = props.sagaData.addressServiceability.broadband.serviceArea;
        let formatted = deployments;

        // Updates product with coverage, or if no product, adds new provider to list
        areas.forEach((area) => {
            // Look to see if area exists already
            const existingDeploymentIndex = formatted.findIndex((deployment) => deployment.provider.providerId === area.provider.providerId);
            if (existingDeploymentIndex >= 0) {
                // Search deployments services for product to attach coverage information
                formatted[existingDeploymentIndex].services.forEach((service, index) => {
                    const types = service.aliases ? [service.technology, ...service.aliases] : [service.technology];
                    // If match is found, attach coverage information and flag
                    if (arraysContainMatchingItem(area.technologies, types)) {
                        formatted[existingDeploymentIndex].services[index] = {
                            ...service,
                            isFromArea: true,
                            coverage: area.coverage,
                        }
                    }
                })
            } else {
                // Insert non-existing provider for unmatched areas
                const newEntry = {
                    provider: area.provider,
                    services: [],
                };

                formatted.push(newEntry);
            }
        });

        return formatted;
    };

    const services = formattedServices();
    const sagaResults = services.map((d) => (
        <ProviderServices key={d.provider.providerId}
                          holdingCompanyId={d.provider.providerId}
                          holdingCompanyName={d.provider.name}
                          technologies={d.services}
        />
    ));
    const harbResults = props.sagaData.addressServiceability.offers.providers.map((m) => (
        <HarbingerOffers key={m.provider.providerId}
                         name={m.provider.name}
                         products={m.products}
                         promotions={m.promotions}/>
    ));

    return (
        <>
            <Typography variant="h4" gutterBottom component="div">SAGA Results ({sagaResults.length})</Typography>
            <p>Services ending with * are from Service Area results</p>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="h6" gutterBottom
                                               component="div">Provider</Typography></TableCell>
                        <TableCell><Typography variant="h6" gutterBottom
                                               component="div">Services</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sagaResults}
                </TableBody>
            </Table>

            <StyledDivider />

            <Typography variant="h4" gutterBottom component="div">Harbinger Results</Typography>
            <Table stickyHeader border={1}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6" gutterBottom component="div">Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" gutterBottom component="div">Speed (Down/Up)</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" gutterBottom component="div">Pricing</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" gutterBottom component="div">Promotions</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {harbResults}
                </TableBody>
            </Table>
        </>
    );
}
